<template>
  <b-form @submit.prevent="submit" autocomplete="off">
    <b-form-group label="Nombre">
      <b-form-input
        required
        type="text"
        v-model="origenDatos.nombre"
        placeholder="Nombre del origen de datos"
      ></b-form-input>
    </b-form-group>

    <b-form-group :label="$t('Tipo de origen')">
      <b-form-select required v-model="origenDatos.tipoOrigen" :options="tiposOrigenDatos"> </b-form-select>
    </b-form-group>

    <b-form-group label="URL">
      <b-form-input
        required
        type="text"
        v-model="origenDatos.endpoint"
        placeholder="URL hasta el endpoint"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Estado">
      <b-form-checkbox multiple v-model="origenDatos.activo" switch value="1" unchecked-value="0">
        <b v-if="origenDatos.activo == true">Activo</b>
        <b v-else>Inactivo</b>
      </b-form-checkbox>
    </b-form-group>
    <botonera-edicion url-cancelar="/administracion/origenes-de-datos"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";
import * as TipoOrigenDatos from "@/enum/TipoOrigenDatos";
import * as apiOrigenesDatos from "@/services/origenesDatos.js";

export default {
  data() {
    return {
      tiposOrigenDatos: TipoOrigenDatos.combo,
      plantillasRadar: [],
      preguntas: [],
      tiposExpediente: [],
      camposPersonalizados: [],
      discriminarPor: [],
    };
  },
  props: {
    origenDatos: Object,
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

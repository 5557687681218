<template>
  <page fluid="lg" title="Editar origen de datos">
    <formulario :origenDatos="origenDatos" @submit="actualizar"> </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiOrigenesDatos from "@/services/origenesDatos.js";

export default {
  components: { Formulario },
  data() {
    return {
      origenDatos: {},
    };
  },
  methods: {
    async cargar(id) {
      let resultado = await apiOrigenesDatos.cargar(id);
      this.origenDatos = resultado;
    },
    async actualizar() {
      let ok = await apiOrigenesDatos.actualizar(this.origenDatos);
      if (ok) {
        this.$emit("userMessage", "Se ha actualizado el origen de datos");
        this.$router.push({ path: "/administracion/origenes-de-datos" });
      }
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
